@import url("https://fonts.googleapis.com/css?family=Poppins");
input[type="text"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  background-color: #f2f2f9;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type="number"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;

  border-radius: 4px;
  box-sizing: border-box;
}
input[type="submit"] {
  width: 100%;
  background-color: #3386ff;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins";
}
option[type="text"] {
  width: 100%;
  background-color: #3386ff;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins";
}
input[type="select"] {
  width: 100%;
  background-color: #3386ff;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins";
}

.input[type="button"] {
  width: 100%;
  background-color: #40453e;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover {
  background-color: #225eb9;
}
label {
  font-weight: 600;
}
h1 {
  color: #4285f4;
}
