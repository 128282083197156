/* Add this CSS to your component's CSS file or a global CSS file */

.input-container {
    display: flex;
    align-items: center;
    margin:40px;
    
  }
  
  .input-container label {
    flex: 1;
    display: flex;
    align-items: center;
    padding-top: 5px;
    margin-right: 10px; 
  }
  
  .input-container input[type="text"] {
    width: 60%;
  }
  