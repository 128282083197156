/* Assuming you have a container class for the heatmap */
.heatmap-container {
  cursor: pointer;
}

.heatmap-container:hover {
  opacity: 0.7; /* Optionally, reduce opacity on hover for visual feedback */
}

/* Styling for the specific cell hover effect */
.plotly .hoverlayer .hovertext {
  opacity: 1 !important;
  background-color: rgba(255, 255, 255, 0.8) !important; /* Adjust as needed */
  border: 1px solid #ccc !important; /* Adjust as needed */
  pointer-events: none;
}

/* Styling for the "Click me" message */
.plotly .hoverlayer .hovertext .click-me-message {
  font-weight: bold;
  color: blue; /* Adjust as needed */
  pointer-events: none;
}
